import { graphql, useStaticQuery } from 'gatsby';

export default function useIcons() {
  const data = useStaticQuery(graphql`
    query {
      chevronDown: file(relativePath: { eq: "chevronDown.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 60, height: 60)
        }
      }
      arrowRight: file(relativePath: { eq: "arrowRight.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 30)
        }
      }
      arrowLeft: file(relativePath: { eq: "arrowLeft.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 30, height: 30)
        }
      }
    }
  `);
  return data;
}

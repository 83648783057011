import styled from 'styled-components';
import Link from 'gatsby-link';

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: center;
`;

export const BranchesContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BranchesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 29px;
  max-width: 850px;
  overflow: scroll;
  padding: 20px;
  margin-bottom: 48px;
`;

export const BranchListItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 8.5px;
`;

export const BranchTitle = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: var(--text-primary);
`;

export const BranchItemContainer = styled.div<{ primaryColor: string }>`
  border-radius: 14px;
  width: 384px;
  height: 223px;
  background-color: ${({ primaryColor }) => primaryColor};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
    transform: scale(1.05);
  }
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

export const BranchLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const BranchLogo = styled.img`
  width: 120px;
  height: 120px;
  user-select: none;
`;

export const LogoContainer = styled.div`
  position: absolute;
  bottom: 12px;
`;
